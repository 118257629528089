<template>
  <q-dialog v-model="isOpen" :maximized="$q.screen.width < 700">
    <q-card style="min-width: 60vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Get dynamic location') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="hide"
        />
      </q-card-section>

      <q-card-section v-if="isLoading" class="q-pt-xs">
        <div class="text-subtitle1 text-center q-mb-sm">
          {{ $t('Current executed product') }}
        </div>

        <div v-if="item" class="row items-center rounded shadow q-py-xs q-px-sm">
          <div class="col">
            <img
              style="width: 80px; height: 80px; object-fit: contain;"
              :src="item.image || fallbackImage"
              @error="onImageLoadFailure($event)"
            >
          </div>

          <div class="text-caption col">
            {{ item.name }}
          </div>

          <div class="col">
            {{ item.count - item.quantityPlace }}
          </div>

          <div class="col" style="min-width: 124px;">
            {{ item.sku }}
          </div>
        </div>

        <div
          v-else
          class="text-center q-pa-sm"
        >
          <q-spinner
            color="light-blue-9"
            size="3rem"
          />
        </div>
      </q-card-section>

      <q-card-section v-else class="q-pb-none">
        <div class="row full-width">

          <form-builder class="col-6 q-pa-xs" :schema="schema" />

          <q-btn
              color="grey-6"
              :label="$t('Request location')"
              class="q-mr-sm"
              icon="add"
              no-caps
              @click="requestLocation"
          />

        </div>

      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="hide"
        />

      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'DynamicLocationModal',
  emits: ['submit'],
  data () {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        rowsPerPage: 250
      },
      isOpen: false,
      selectedItems: [],
      items: [],
      item: null,
      warehouse: null,
      model: {
        place: null
      },
      type: '',
    }
  },
  computed: {
    schema () {
      return {
        fields: [
          {
            type: 'multiselect',
            label: this.$t('Select a storage location'),
            field: 'place',
            value: this.model.place,
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                const path = this.loadPath(row)
                return `${path.join(' - ')} (${row.code}*) (${row.type})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: [
                  { type: 'in', field: 'state', values: ['active', 'normal']}
                ]
              }

              query.filter.push({ type: 'in', field: 'type' , values: ['room']})

              const value = this.warehouse.type === 'virtual'
                ? this.getId(this.warehouse._embedded.parent)
                : this.warehouse.id

              query.filter.push({ type: 'eq', field: 'warehouse', value })

              return this.$service.storagePlace.getAll(query)
            },
            onChange: (place) => {
              this.model.place = place
            }
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleClick (type) {
      this.selectedType = type
    },
    loadPath (place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [(place.placeAlias || '')]
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    },
    getId (warehouse) {
      if (warehouse.id) {
        return warehouse.id
      }

      return warehouse._links.self.href.split('/').pop()
    },
    show (warehouse) {
      return Promise.resolve(warehouse)
        .then(warehouse => {
          return warehouse.type === 'virtual' && warehouse._embedded
            ? warehouse
            : this.$service.warehouse.get(warehouse.id)
        })
        .then(warehouse => {
          this.warehouse = warehouse

          this.isOpen = true
        })
    },
    hide () {
      this.isOpen = false
    },
    requestLocation () {
      if (!this.model.place?.id) {
        this.addErrorNotification('Please select room')

        return;
      }

      const warehouseId = this.warehouse.type === 'virtual'
          ? this.getId(this.warehouse._embedded.parent)
          : this.warehouse.id

      const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let randomString = ''
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)

        randomString += characters.charAt(randomIndex)
      }

      const data = {
        warehouse: warehouseId,
        parent: this.model.place.id,
        type: 'dynamic',
        placeAlias: randomString,
      }

      return this.$service.storagePlace.save(data, null).then(place => {
        const query = {
          filter: [
            { type: 'eq', field: 'id', value: place.id }
          ]
        }

        this.$service.storagePlace.printAll(query)
            .then(({ text }) => {
              this.$service.printer.print(text, 'LABEL', undefined, true)
            })
      })
    },
    save () {
      return this.saveItems()
    },
    saveItems (results = []) {
      this.isLoading = true
      return Promise.resolve(this.selectedItems.length > 0)
        .then(hasItems => {
          return hasItems
            ? Promise.resolve(this.selectedItems)
            : Promise.reject(new Error('No items for allocate!'))
        })
        .then(items => {
          this.item = { ...items[0] }
          return Promise.all([this.item, this.saveItem(this.item)])
        })
        .then(([item, result]) => {
          results = [...(results || []), result]

          this.selectedItems = this.selectedItems.slice(1)

          this.items = this.items.filter(({ id }) => id !== item.id)

          if (!this.selectedItems[0]) {
            this.item = null
            this.isLoading = false
            this.hide()
            return results
          }

          return this.save(results)
        })
        .catch(error => {
          console.trace(error)
          this.addErrorNotification(error)
          this.isLoading = false
        })
    }
  }
}
</script>
