<template>
  <q-dialog v-model="isOpen">
    <q-card v-if="isOpen" class="modal-lg">
      <q-card-section class="row">

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-carousel
          :model-value="slide"
          transition-prev="scale"
          transition-next="scale"
          animated
          height="auto"
      >
        <q-carousel-slide name="options" class="row justify-center">
          <div
              class="col-4 q-px-sm"
              style="min-height: 200px; min-width: 300px"
          >
            <q-card
                class="fit card--clickable"
                @click="handleForm([...unlockedFields, 'new'], 'new')"
            >
              <q-card-section
                  class="row fit text-center items-center justify-center"
              >
                <div>
                  <q-icon name="note_add" size="2rem"/>

                  <h5 class="q-my-none">
                    {{ $t('Create New') }}

                    <br>

                    {{ 'Purchase order' }}
                  </h5>
                </div>
              </q-card-section>
            </q-card>
          </div>

          <div
              class="col-4 q-px-sm"
              style="min-height: 200px; min-width: 300px"
          >
            <q-card
                class="fit card--clickable"
                @click="handleForm([...unlockedFields, 'to', 'from']  , 'form')"
            >
              <q-card-section
                  class="row fit text-center items-center justify-center"
              >
                <div>
                  <q-icon name="drive_file_move" size="2rem"/>

                  <h5 class="q-my-none">
                    {{ $t('Move to Existing') }}

                    <br>

                    {{ 'Purchase order' }}
                  </h5>
                </div>
              </q-card-section>
            </q-card>
          </div>

          <div
              class="col-4 q-px-sm"
              style="min-height: 200px; min-width: 300px"
          >
            <q-card
                class="fit card--clickable"
                @click="handleForm([...unlockedFields, 'duplicate'], 'duplicate')"
            >
              <q-card-section
                  class="row fit text-center items-center justify-center"
              >
                <div>
                  <q-icon name="drive_file_move" size="2rem"/>

                  <h5 class="q-my-none">
                    {{ $t('Duplicate PO with') }}

                    <br>

                    {{ 'selected items' }}
                  </h5>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </q-carousel-slide>

        <q-carousel-slide name="new" class="q-pa-none">
          <q-card-section class="q-py-none">
            <div
                v-if="unlockedFields.includes('new')"
                class="text-center text-grey-7 text-subtitle1 q-mb-sm"
            >
              {{
                this.$t(
                    'Move to New Acceptance'
                )
              }}
            </div>

          </q-card-section>

          <q-card-section class="text-center">
            <q-table
                row-key="id"
                :rows="model"
                :columns="columns"
                virtual-scroll
                binary-state-sort
                flat
                :pagination="{ page: 1, rowsPerPage: model.length, rowsNumber: model.length }"
                :rows-per-page-options="[]"
                :hide-bottom="true"
            >
              <template v-slot:body="props">
                <q-tr
                    :props="props"
                    class="clickable"
                >
                  <q-td
                      key="number"
                      :props="props"
                      auto-width
                  >
                    {{ props.rowIndex + 1 }}
                  </q-td>

                  <q-td
                      key="image"
                      :props="props"
                      style="width: 35%"
                  >
                    <q-card style="min-width: 100px;max-width: 50%; text-align: center">
                      <div>
                        <img
                            :src="props.row._embedded?.productOffer?.image || appOptions.fallbackImage"
                            style="height: 50%; width: 50%; object-fit: contain;"
                        >
                      </div>
                    </q-card>
                  </q-td>

                  <q-td
                      key="name"
                      :props="props"
                      style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
                  >
                    <div class="text-subtitle2 q-my-sm">
                      {{ props.row.name }}
                    </div>
                  </q-td>

                  <q-td
                      key="actions"
                      :props="props"
                      style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
                  >
                    <q-btn
                        icon="close"
                        flat
                        color="negative"
                        @click="handleDelete(props.row)"
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>

          <q-card-section class="text-center q-mt-md">
            <q-btn
                color="white"
                text-color="dark"
                class="q-mr-sm"
                :label="$t('Discard')"
                @click="hide"
            />
            <q-btn
                color="light-blue-9"
                text-color="white"
                class="q-mr-sm"
                :label="$t('Save')"
                @click="handleNew"
            />

            <q-btn
                v-if="model && model.id"
                color="danger"
                text-color="white"
                :label="$t('Delete')"
                @click="handleDelete"
            />
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide name="form" class="q-pa-none">
          <q-card-section class="q-py-none">
            <div
                v-if="unlockedFields.includes('from')"
                class="text-center text-grey-7 text-subtitle1 q-mb-sm"
            >
              {{
                this.$t(
                    'Select Acceptance you want Acceptance Items to be moved in.'
                )
              }}
            </div>

            <form-builder :schema="schema"/>
          </q-card-section>

          <q-card-section>
            <q-tabs align="center" class="text-center">
              <q-tab name="day" label="Day" @click="handlePeriod('day')"></q-tab>
              <q-tab name="week" label="Week" @click="handlePeriod('week')"></q-tab>
              <q-tab name="month" label="Month" @click="handlePeriod('month')"></q-tab>
              <q-tab name="year" label="Year" @click="handlePeriod('year')"></q-tab>
              <q-tab name="all" label="All" @click="handlePeriod('always')"></q-tab>
            </q-tabs>
          </q-card-section>

          <q-card-section class="text-center">
            <q-table
                row-key="id"
                :rows="model"
                :columns="columns"
                virtual-scroll
                binary-state-sort
                flat
                :pagination="{ page: 1, rowsPerPage: model.length, rowsNumber: model.length }"
                :rows-per-page-options="[]"
                :hide-bottom="true"
            >
              <template v-slot:body="props">
                <q-tr
                    :props="props"
                    class="clickable"
                >
                  <q-td
                      key="number"
                      :props="props"
                      auto-width
                  >
                    {{ props.rowIndex + 1 }}
                  </q-td>

                  <q-td
                      key="image"
                      :props="props"
                      style="width: 35%"
                  >
                    <q-card style="min-width: 100px;max-width: 50%; text-align: center">
                      <div>
                        <img
                            :src="props.row._embedded?.productOffer?.image || appOptions.fallbackImage"
                            style="height: 50%; width: 50%; object-fit: contain;"
                        >
                      </div>
                    </q-card>
                  </q-td>

                  <q-td
                      key="name"
                      :props="props"
                      style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
                  >
                    <div class="text-subtitle2 q-my-sm">
                      {{ props.row.name }}
                    </div>
                  </q-td>

                  <q-td
                      key="actions"
                      :props="props"
                      style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
                  >
                    <q-btn
                        icon="close"
                        flat
                        color="negative"
                        @click="handleDelete(props.row)"
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>

          <q-card-section class="text-center q-mt-md">
            <q-btn
                color="white"
                text-color="dark"
                class="q-mr-sm"
                :label="$t('Discard')"
                @click="hide"
            />
            <q-btn
                color="light-blue-9"
                text-color="white"
                class="q-mr-sm"
                :disable="!acceptance"
                :label="$t('Save')"
                @click="save"
            />

            <q-btn
                v-if="model && model.id"
                color="danger"
                text-color="white"
                :label="$t('Delete')"
                @click="handleDelete"
            />
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide name="duplicate" class="q-pa-none">
          <q-card-section class="q-py-none">
            <div
                v-if="unlockedFields.includes('duplicate')"
                class="text-center text-grey-7 text-subtitle1 q-mb-sm"
            >
              {{
                this.$t(
                    'Move to Duplicated Acceptance'
                )
              }}
            </div>

          </q-card-section>

          <q-card-section class="text-center">
            <q-table
                row-key="id"
                :rows="model"
                :columns="columns"
                virtual-scroll
                binary-state-sort
                flat
                :pagination="{ page: 1, rowsPerPage: model.length, rowsNumber: model.length }"
                :rows-per-page-options="[]"
                :hide-bottom="true"
            >
              <template v-slot:body="props">
                <q-tr
                    :props="props"
                    class="clickable"
                >
                  <q-td
                      key="number"
                      :props="props"
                      auto-width
                  >
                    {{ props.rowIndex + 1 }}
                  </q-td>

                  <q-td
                      key="image"
                      :props="props"
                      style="width: 35%"
                  >
                    <q-card style="min-width: 100px;max-width: 50%; text-align: center">
                      <div>
                        <img
                            :src="props.row._embedded?.productOffer?.image || appOptions.fallbackImage"
                            style="height: 50%; width: 50%; object-fit: contain;"
                        >
                      </div>
                    </q-card>
                  </q-td>

                  <q-td
                      key="name"
                      :props="props"
                      style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
                  >
                    <div class="text-subtitle2 q-my-sm">
                      {{ props.row.name }}
                    </div>
                  </q-td>

                  <q-td
                      key="actions"
                      :props="props"
                      style="max-width: 150px; word-wrap: break-word; white-space: normal !important; width: 25%"
                  >
                    <q-btn
                        icon="close"
                        flat
                        color="negative"
                        @click="handleDelete(props.row)"
                    />
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>

          <q-card-section class="text-center q-mt-md">
            <q-btn
                color="white"
                text-color="dark"
                class="q-mr-sm"
                :label="$t('Discard')"
                @click="hide"
            />
            <q-btn
                color="light-blue-9"
                text-color="white"
                class="q-mr-sm"
                :label="$t('Save')"
                @click="handleDuplicate"
            />

            <q-btn
                v-if="model && model.id"
                color="danger"
                text-color="white"
                :label="$t('Delete')"
                @click="handleDelete"
            />
          </q-card-section>
        </q-carousel-slide>
      </q-carousel>
    </q-card>

  </q-dialog>

</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'AcceptanceItemsMovementModal',
  components: {},
  data() {
    return {
      isOpen: false,
      slide: 'options',
      unlockedFields: [],
      oldAcceptance: null,
      period: null,
      acceptance: null,
      model: {},
      typeNames: {
        employee: 'basket'
      },
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t(''),
          name: 'actions',
          align: 'right'
        }
      ],
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    title() {
      if (!this.model.type) {
        return this.$t('location')
      }

      return this.$t(this.typeNames[this.model.type] || this.model.type)
    },
    schema() {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Purchase order'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.acceptance,
                required: true,
                customLabel(row) {
                  if (row && typeof row === 'object') {
                    const formattedDate = moment(row.created.date).format('MM/DD/YYYY hh:mm A z') // Update the format as per your requirement
                    return `${row.id} (${formattedDate})`
                  }
                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'eq', value: this.oldAcceptance.state },
                      { field: 'warehouse', type: 'eq', value: this.oldAcceptance._embedded.warehouse.id },
                      { field: 'id', type: 'neq', value: this.oldAcceptance.id }
                    ]
                  }
                  if (this.period) {
                    query.filter.push({ field: 'created', type: 'gte', value: this.period })
                  }
                  return this.$service.acceptance.getAll(query)
                },
                onChange: acceptance => {
                  this.acceptance = acceptance
                }
              },
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'setAcceptance',
      'setCleanAcceptanceItems',
      'setAcceptanceItems',
      'setCleanAcceptanceItems',
      'updateAcceptanceEmbedded',
      'setNewAcceptance',
      'updateAcceptance'
    ]),
    handlePeriod(period) {
      // filter[0][field]: created
      // filter[0][type]: gte
      // filter[0][value]: 2023-06-06T00:00

      const now = new Date()
      if (period === 'week') {
        const oneWeekBefore = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().slice(0, 16)
        this.period = oneWeekBefore
      } else if (period === 'day') {
        const oneDayBefore = new Date(now.getTime() - 24 * 60 * 60 * 1000).toISOString().slice(0, 16)
        this.period = oneDayBefore
      } else if (period === 'month') {
        const oneMonthBefore = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).toISOString().slice(0, 16)
        this.period = oneMonthBefore
      } else if (period === 'year') {
        const oneYearBefore = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).toISOString().slice(0, 16)
        this.period = oneYearBefore
      } else {
        this.period = null
      }
    },
    save() {
      const promises = this.model.map(item =>
          this.$service.acceptanceItem.save({
            document: this.acceptance.id,
            comment: `From Original PO: ${this.oldAcceptance.id}`
          }, item.id)
      )

      Promise.all(promises)
          .then(() => {
            this.setAcceptance(null)
            this.setAcceptanceItems([])
            this.setCleanAcceptanceItems([])
            this.updateAcceptance({
              state: this.oldAcceptance.state,

            })
            this.$router.push(`/inbound/purchase-orders/entity/products/${this.acceptance.id}`)
          })
    },
    handleDelete(item) {
      this.model = this.model.filter(e => e.id !== item.id)
    },
    show(items, acceptance = {}) {
      this.model = items
      this.oldAcceptance = acceptance

      this.isOpen = true

    },
    hide() {
      this.slide = 'options'
      this.isOpen = false
    },
    handleNew() {
      const newAccepnace = {}
      newAccepnace.type = this.oldAcceptance.type
      newAccepnace.state = this.oldAcceptance.state
      newAccepnace.warehouse = this.oldAcceptance._embedded.warehouse.id

      this.$service.acceptance.save(newAccepnace).then((acceptance) => {
        return this.$service.acceptanceItem.saveBulk(this.model, {
          document: acceptance.id,
          comment: `From Original PO: ${this.oldAcceptance.id}`
        }).then(() => {
          this.setAcceptance(acceptance)

          this.$router.push(`/inbound/purchase-orders/entity/products/${acceptance.id}?hasItems=true`)
        })
      })
    },
    handleDuplicate() {
      const newAccepnace = {}
      newAccepnace.state = 'new'
      newAccepnace.warehouse = this.oldAcceptance._embedded.warehouse.id
      newAccepnace.type = this.oldAcceptance.type
      newAccepnace.itemsStatus = this.oldAcceptance.itemsStatus


      this.$service.acceptance.save(newAccepnace).then((acceptance) => {
        const items = this.model.map(e => {
          e.count = 1
          e.quantityExpected = 1
          e.quantityReal = 0
          e.quantityAllocated = 0
          if (e._embedded && e._embedded.productOffer) {
            e.productOffer = e._embedded.productOffer.id
          }
          let shop = ''
          if (e._embedded && e._embedded.productOffer && e._embedded.productOffer._embedded && e._embedded.productOffer._embedded.virtualShop) {
            shop = e._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()
          } else if (e._embedded && e._embedded.productOffer && e._embedded.productOffer._embedded && e._embedded.productOffer._embedded.shop) {
            shop = e._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
          }
          if (shop) {
            e.shop = shop
          }

          delete e.id
          delete e.items
          delete e.created
          delete e.updated
          delete e._embedded.acceptance

          return e
        })

        return this.$service.acceptanceItem.saveBulk(items, {
          document: acceptance.id,
          quantityExpected: 1,
          comment: `From Original PO: ${this.oldAcceptance.id}`,
        }).then(() => {
          this.setAcceptance(acceptance)

          this.$router.push(`/inbound/purchase-orders/entity/products/${acceptance.id}?hasItems=true`)
        })
      })
    },
    handleForm(fields, form) {
      this.unlockedFields = fields
      this.slide = form
    },
  }
}
</script>
