<template>
  <div>
    <q-legend
        class="q-mb-sm"
        :label="$t('Delivery')"
    />

    <form-builder :schema="schema"/>

  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'
import { getCarrier } from '@/apps/app/helpers'

// Components
export default {
  name: 'AcceptanceDeliveryRequest',
  emits: ['delivery-request-change' , 'has-tracking-number-value' ,'has-sender-name-value'],
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    senderDisabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    required: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      legalEntityModal: false,
      isEnabledSenderFilter: true,
      isDeliveryServiceDetected: false,
      country: null,
      updateField: '',
      hasSenderNameValue: false,
      hasTrackingNumberValue: false,
    }
  },
  updated () {
    if(this.acceptance?._embedded?.deliveryRequest?.hasTrackingNumberValue){
      this.hasTrackingNumberValue = this.acceptance._embedded?.deliveryRequest?.hasTrackingNumberValue
    }
    if(this.acceptance?._embedded?.deliveryRequest?.hasSenderNameValue){
      this.hasSenderNameValue = this.acceptance._embedded.deliveryRequest.hasSenderNameValue
    }
  },
  computed: {
    ...mapGetters([
      'acceptance'
    ]),
    schema () {
      return {
        isLoading: !this.acceptance,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: '_embedded.deliveryRequest.sender',
                value: this.getSender(),
                required: true,
                disabled: this.disabled || this.senderDisabled,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    if (row.name) {
                      return `${row.name} (${row.id})`
                    } else {
                      return ` `
                    }
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (sender) => {
                  this.updateAcceptanceEmbedded({ deliveryRequest: { _embedded: { sender } } })
                },
                btnStyleClasses: 'q-pl-sm'
              },
              {
                type: 'input',
                label: this.$t('Tracking number'),
                field: '_embedded.deliveryRequest.trackingNumber',
                value: this.acceptance?._embedded?.deliveryRequest?.trackingNumber,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                required: this.required || !this.isTrackingNumber(),
                disabled: this.isAcceptanceNew(),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onChange: trackingNumber => {
                  const deliveryService = getCarrier(trackingNumber)

                  if (deliveryService.id) {
                    this.updateAcceptanceEmbedded({ deliveryRequest: { _embedded: { deliveryService } } })

                    this.$emit('delivery-request-change', this.acceptance)

                    this.isDeliveryServiceDetected = true
                  } else {
                    this.isDeliveryServiceDetected = false
                  }

                  this.updateAcceptanceEmbedded({ deliveryRequest: { trackingNumber } })
                  this.$emit('delivery-request-change', this.acceptance)
                },
                buttons:[
                  {
                    if: this.required,
                    id: 'et1',
                    color: this.hasTrackingNumberValue
                        ? 'positive'
                        : 'dark',
                    label: this.$t('Unknown'),
                    wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                    size: 'lg',
                    styleClasses: 'q-ml-sm',
                    disabled: false,
                    onClick: () => {
                      if(!this.hasTrackingNumberValue) {
                        this.hasTrackingNumberValue = true
                        this.isTrackingNumber()
                        this.$emit('has-tracking-number-value' , this.hasTrackingNumberValue)
                      } else {
                        this.hasTrackingNumberValue = false
                        this.isTrackingNumber()
                        this.$emit('has-tracking-number-value' , this.hasTrackingNumberValue)
                      }
                    }
                  },
                ],
                btnStyleClasses: 'q-pl-sm',
              },
              {
                type: 'multiselect',
                label: this.$t('Carrier'),
                field: '_embedded.deliveryRequest.deliveryService',
                value: this.getDeliveryService(),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                required: this.required,
                disabled: this.isCarrierReadonly(),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.country && this.isEnabledSenderFilter) {
                    query.filter.push({ type: 'eq', field: 'country', value: this.country })
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: deliveryService => {
                  this.updateAcceptanceEmbedded({ deliveryRequest: { _embedded: { deliveryService } } })
                  this.$emit('delivery-request-change', this.acceptance)
                },
                btnStyleClasses: 'q-pl-sm'
              },
              {
                type: 'input',
                label: this.$t('Sender name'),
                field: '_embedded.deliveryRequest.senderName',
                value: this.acceptance?._embedded?.deliveryRequest?.senderName,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                required: this.required || !this.isSenderNumber(),
                disabled: this.isAcceptanceNew(),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onChange: senderName => {
                  this.updateAcceptanceEmbedded({ deliveryRequest: { senderName } })
                  this.$emit('delivery-request-change', this.acceptance)
                },
                buttons:[
                  {
                    if: this.required,
                    id: 'et1',
                    color: this.hasSenderNameValue
                        ? 'positive'
                        : 'dark',
                    label: this.$t('Unknown'),
                    wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                    size: 'lg',
                    styleClasses: 'q-ml-sm',
                    disabled: false,
                    onClick: () => {
                      if(!this.hasSenderNameValue) {
                        this.hasSenderNameValue = true
                        this.isSenderNumber()
                        this.$emit('has-sender-name-value' , this.hasSenderNameValue)
                      } else {
                        this.hasSenderNameValue = false
                        this.isSenderNumber()
                        this.$emit('has-sender-name-value' , this.hasSenderNameValue)
                      }
                    }
                  },
                ],
                btnStyleClasses: 'q-pl-sm',
              },
              {
                type: 'input',
                label: this.$t('Shipping Cost'),
                field: '_embedded.deliveryRequest.retailPrice',
                value: this.acceptance?._embedded?.deliveryRequest?.retailPrice,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                disabled: this.disabled || this.senderDisabled,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onChange: retailPrice => {
                  const deliveryService = getCarrier(retailPrice)

                  if (deliveryService.id) {
                    this.updateAcceptanceEmbedded({ deliveryRequest: { _embedded: { deliveryService } } })

                    this.$emit('delivery-request-change', this.acceptance)

                    this.isDeliveryServiceDetected = true
                  } else {
                    this.isDeliveryServiceDetected = false
                  }

                  this.updateAcceptanceEmbedded({ deliveryRequest: { retailPrice } })
                  this.$emit('delivery-request-change', this.acceptance)
                }
              },
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateAcceptance',
      'addErrorNotification',
      'updateAcceptanceEmbedded'
    ]),
    getSender () {
      return this.acceptance?._embedded?.deliveryRequest?._embedded?.sender?.name || ''
    },
    getDeliveryService () {
      return this.acceptance?._embedded?.deliveryRequest?._embedded?.deliveryService
    },
    isCarrierReadonly () {
      if (this.isDeliveryServiceDetected) {
        return true
      }

      return false
    },
    isTrackingNumber () {
        if (this.hasTrackingNumberValue) {
          return true
        }
        
        return false
    },
    isAcceptanceNew () {
      if (this.acceptance && this.acceptance.state) {
        return true
      }
      return false
    },
    isSenderNumber () {
      if (this.hasSenderNameValue) {
        return true
      }
      return false
    },
    isSenderNameRequired () {
      if (this.acceptance && this.acceptance._embedded.deliveryRequest && this.acceptance._embedded.deliveryRequest.trackingNumber) {
        return false
      }

      return true
    }
  }
}
</script>
