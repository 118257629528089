<template>
  <q-dialog v-model="isOpen" class="modal-xl">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Services') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center q-pt-none">
        <q-btn
            color="green-6"
            size="md"
            :label="$t('Supplies list')"
            no-caps
            @click="toggleSupplies"
        />

        <q-btn
            color="green-6"
            size="md"
            class="q-ml-sm"
            icon="add"
            :label="$t('One-time service')"
            no-caps
            @click="toggle"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-table
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="services"
            :columns="columns"
            v-model:pagination="pagination"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <q-td
                  key="type"
                  :props="props"
                  class="text-subtitle1"
              >
                <strong v-if="props.row._embedded && props.row._embedded.supply">
                  {{ $t(props.row._embedded.supply.type) }}
                </strong>

                <strong v-else>
                  {{ $t('custom') }}
                </strong>
              </q-td>

              <q-td
                  key="name"
                  :props="props"
              >
                <div class="text-subtitle1 text-weight-bold">
                  {{ props.row.name }}
                </div>

                <strong
                    v-if="props.row._embedded && props.row._embedded.supply && props.row._embedded.supply.type === 'package' && props.row._embedded.supply.dimensions"
                    style="text-weight-bold text-subtitle2"
                >
                  {{
                    `${props.row._embedded.supply.dimensions.x}/${props.row._embedded.supply.dimensions.y}/${props.row._embedded.supply.dimensions.z}`
                  }}
                </strong>
              </q-td>

              <q-td
                  key="price"
                  :props="props"
              >
                {{ Number((props.row.price || 0)).toFixed(2) }}
                <q-popup-edit v-model="props.row.price" auto-save v-slot="scope" style="align-content: center">
                  <q-input type="number" v-model="scope.value" dense autofocus
                           @keyup.enter="handleChange(scope.value,props.row , 'price')"/>
                </q-popup-edit>
              </q-td>

              <q-td
                  key="quantity"
                  :props="props"
              >
                {{ props.row.count || '--' }}
                <q-popup-edit v-model="props.row.count" auto-save v-slot="scope" style="align-content: center">
                  <q-input type="number" v-model="scope.value" dense autofocus
                           @keyup.enter="handleChange(scope.value,props.row , 'count')"/>
                </q-popup-edit>
              </q-td>

              <q-td
                  key="total"
                  :props="props"
              >
                {{ ((props.row.count || 0) * (props.row.price || 0)).toFixed(2) }}
              </q-td>

              <q-td
                  key="acceptanceItem"
                  :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.acceptanceItem">
                  {{ props.row._embedded.acceptanceItem.id }}
                </span>

                <span v-else>
                    {{ '-' }}
                </span>
              </q-td>

              <q-td
                  key="status"
                  :props="props"
              >
                <q-chip v-if="props.row.state === 'requested'" color="orange-7">
                  {{ props.row.state }}
                </q-chip>

                <span v-else> {{ '-' }} </span>
              </q-td>

              <q-td
                  key="import"
                  :props="props"
                  auto-width
              >
                <div v-if="props.row.eav && !props.row.eav['storage-services-document']">
                  <q-btn
                      label="Import PDF"
                      flat
                      color="positive"
                      @click="openFileInput"
                  />
                  <q-file
                      ref="fileInput"
                      accept=".pdf"
                      filled
                      class="d-none"
                      @update:model-value="handleUpload(props.row , $event)"
                  />
                </div>
                <div v-else>
                  <q-btn
                      class="q-mr-sm"
                      icon="download"
                      size="sm"
                      color="positive"
                      filled
                      @click="handleDownload(props.row)"
                  />
                  <q-btn
                      icon="delete"
                      size="sm"
                      color="negative"
                      filled
                      @click="handleDeletePDF(props.row)"
                  />
                </div>

              </q-td>

              <q-td
                  key="actions"
                  :props="props"
                  auto-width
              >
                <q-btn
                    icon="close"
                    flat
                    color="negative"
                    @click="handleDelete(props.row)"
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <supplies-modal ref="suppliesModal" @on-submit="save"/>

    <service-modal ref="serviceModal" @on-submit="save"/>
  </q-dialog>
</template>

<script>
// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import ServiceModal from './ServiceModal.vue'
import SuppliesModal from './SuppliesModal.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'AcceptanceServicesModal',
  components: {
    SuppliesModal,
    ServiceModal
  },
  data () {
    return {
      isOpen: false,
      services: [],
      currentSupply: null,
      columns: [
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'quantity',
          align: 'left'
        },
        {
          label: this.$t('Total'),
          name: 'total',
          align: 'left'
        },
        {
          label: this.$t('Acceptance Item'),
          name: 'acceptanceItem',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'status',
          align: 'left'
        },
        {
          label: 'PDF',
          name: 'import',
          align: 'center'
        },
        {
          label: '',
          name: 'actions',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      acceptance: null,
      model: {},
      extFilter: [],
      filters: [{ type: 'eq', field: 'state', value: 'active' }]
    }
  },
  methods: {
    ...mapMutations([
      'addNotification'
    ]),
    handleDownload (supply) {
      const fileUrl = supply.eav['storage-services-document']
      const link = document.createElement('a')
      link.href = fileUrl
      link.target = '_blank'
      link.setAttribute('download', '')
      link.click()
    },
    handleDeletePDF (supply) {
      this.$service.acceptanceService.save({ eav: { 'storage-services-document': '' } }, supply.id)
          .then(() => {
            this.services = []
            this.isOpen = false
            this.isOpen = true
            this.onRequest()
          })

    },
    handleChange (result, item, field) {
      return this.$service.acceptanceService.save({ [field]: result }, item.id)
    },
    openFileInput () {
      this.$refs.fileInput.$el.click()
    },
    handleUpload (supply, file) {
      if (file) {
        this.$service.acceptanceService.upload(supply.id, file, 'eav-storage-services-document')
            .then(() => {
              this.addNotification('Successfully uploaded file!')
              this.isOpen = false
              this.isOpen = true
            })
      }
    },
    handleDelete (row) {
      this.$service.acceptanceService.save({ state: 'deleted' }, row.id)
          .then(() => {
            this.services = this.services.filter(x => x.id !== row.id)
          })
    },
    toggleSupplies () {
      this.$refs.suppliesModal.open(this.extFilter)
    },
    save (service) {
      return this.$service.acceptanceService.save({ ...service, acceptance: this.acceptance })
          .then(item => {
            this.services = [item, ...this.services]
          })
    },
    toggle () {
      this.$refs.serviceModal.open()
    },
    close () {
      this.isOpen = false
    },
    open (acceptance, model = {}) {
      this.acceptance = acceptance
      this.model = {
        ...model
      }

      this.extFilter = []

      // if (this.model.warehouse) {
      //   this.extFilter = [{ type: 'eq', field: 'warehouse', value: this.model.warehouse.id }]
      // } else {
      //
      // }

      this.filters = [{ field: 'acceptance', type: 'eq', value: acceptance }]

      this.isOpen = true
      this.onRequest()
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || this.pagination
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      return this.$service.acceptanceService.getAll(query)
          .then(data => {
            this.pagination = {
              ...this.pagination,
              page: data.page,
              rowsNumber: data.totalItems
            }

            this.services = data.items

            return data
          })
    }
  }
}
</script>
