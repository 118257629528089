<template>
  <q-dialog v-model="isOpen" :maximized="$q.screen.width < 700">
    <q-card style="min-width: 60vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Allocation without terminal') }}
        </div>

        <q-space/>

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="hide"
        />
      </q-card-section>

      <q-card-section v-if="isLoading" class="q-pt-xs">
        <div class="text-subtitle1 text-center q-mb-sm">
          {{ $t('Current executed product') }}
        </div>

        <div v-if="item" class="row items-center rounded shadow q-py-xs q-px-sm">
          <div class="col">
            <img
                style="width: 80px; height: 80px; object-fit: contain;"
                :src="item.image || fallbackImage"
                @error="onImageLoadFailure($event)"
            >
          </div>

          <div class="text-caption col">
            {{ item.name }}
          </div>

          <div class="col">
            {{ item.quantityReal - item.quantityPlace }}
          </div>

          <div class="col" style="min-width: 124px;">
            {{ item.sku }}
          </div>
        </div>

        <div
            v-else
            class="text-center q-pa-sm"
        >
          <q-spinner
              color="light-blue-9"
              size="3rem"
          />
        </div>
      </q-card-section>

      <q-card-section v-else class="q-pb-none">
        <div class="text-caption text-left q-mb-sm q-px-md q-pb-sm border-bottom">
          <b class="q-mr-sm">{{ $t('Note!') }}</b>

          <span>
            {{
              $t('Using this method for the regular product allocation might lead to different mistakes, for instance, wrong inventory quantity, wrong storage locations! To avoid the mistakes we suggest to use the OA terminal to scan every product.')
            }}
          </span>
        </div>
        <div class="row full-width">

          <form-builder class="col-6 q-pa-xs" :schema="schema"/>

          <div class="col-6 q-pa-xs">
            <q-select
                filled
                :model-value="selectedType.id"
                option-label="label"
                :options="types"
                stack-label
                label="Type"
                @update:model-value="handleClick"
            />
          </div>
        </div>

      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-subtitle1 text-center q-mb-md q-px-md">
          {{ $t('Select products for allocation') }}
        </div>

        <q-table
            style="height: 100%;"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            v-model:selected="selectedItems"
            :loading="isLoading"
            selection="multiple"
            virtual-scroll
            binary-state-sort
        >
          <template v-slot:header-selection="props">
            <div class="bg-light-green-2 border-bottom row items-center justify-center"
                 style="margin: -23px -16px; height: 49px;">
              <q-icon
                  name="check"
                  size="md"
                  :color="selectedItems.length > 0 ? 'teal' : 'grey'"
                  @click="handleSelectAll(props)"
              />
            </div>
          </template>

          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <td class="q-td text-center q-table--col-auto-width">
                <q-icon
                    name="check"
                    size="md"
                    :color="selectedItems.find(({id}) => id === props.row.id) ? 'teal' : 'grey'"
                    @click="handleCheck(props.row)"
                />
              </td>

              <q-td
                  key="image"
                  :props="props"
                  auto-width
              >
                <img
                    style="width: 80px; height: 80px; object-fit: contain;"
                    :src="props.row.image || fallbackImage"
                    @error="onImageLoadFailure($event)"
                >
              </q-td>

              <q-td
                  key="name"
                  :props="props"
                  class="text-caption"
                  auto-width
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                  key="quantity"
                  :props="props"
                  auto-width
              >
                {{ props.row.quantityReal - props.row.quantityPlace }}
              </q-td>

              <q-td
                  key="sku"
                  :props="props"
                  style="min-width: 124px;"
                  auto-width
              >
                {{ props.row.sku }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
            color="dark"
            text-color="white"
            class="q-mr-sm"
            :label="$t('Close')"
            @click="hide"
        />

        <q-btn
            color="light-blue-9"
            text-color="white"
            :label="$t('Save')"
            :disable="!model.place || selectedItems.length <= 0 "
            @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'PlaceItemsModal',
  emits: ['submit'],
  data () {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        rowsPerPage: 250
      },
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Quantity'),
          name: 'quantity',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        },
        {
          label: this.$t('Sku'),
          name: 'sku',
          align: 'left',
          headerClasses: 'bg-light-green-2'
        }
      ],
      fallbackImage: 'assets/img/fallback-image/package.png',
      isOpen: false,
      acceptance: null,
      selectedItems: [],
      items: [],
      item: null,
      warehouse: null,
      model: {
        place: null
      },
      type: '',
      selectedType: { id: 'Orderadmin\\Storage\\Entity\\Place', label: 'Static' },
      types: [
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Room', label: 'Room' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Section', label: 'Section' },
        { id: 'Orderadmin\\Storage\\Entity\\Place', label: 'Static' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Dynamic', label: 'Dynamic' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Employee', label: 'Employee' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Sorting', label: 'Sorting' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Distribution', label: 'Distribution' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected', label: 'Distribution_rejected' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Assembly', label: 'Assembly' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Universal', label: 'Universal' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Pallet', label: 'Pallet' },
        { id: 'Orderadmin\\Storage\\Entity\\Place\\Defected', label: 'Defected' }
      ],
    }
  },
  computed: {
    selectType () {
      if (!this.selectedType) {
        return { id: 'static', label: 'Static' }
      } else {
        return this.selectedType
      }
    },
    schema () {
      return {
        fields: [
          {
            type: 'multiselect',
            label: this.$t('Select a storage location'),
            field: 'place',
            value: this.model.place,
            customLabel: (row) => {
              if (row && typeof row === 'object') {
                const path = this.loadPath(row)
                return `${path.join(' - ')} (${row.code}*) (${row.type})`
              }

              return row
            },
            onScroll: (search, page) => {
              const query = {
                per_page: 25,
                page,
                search,
                filter: [
                  { type: 'in', field: 'state', values: ['active', 'normal'] }
                ]
              }
              if (this.selectedType) {
                query.filter.push({ type: 'instanceof', field: 'p', class: this.selectType.id })
              } else {
                query.filter.push({ type: 'instanceof', field: 'p', class: 'Orderadmin\\Storage\\Entity\\Place' })
              }
              // if (this.type) {
              //   query.filter.push({ type: 'eq', field: 'type', value: this.type })
              // }

              const value = this.warehouse.type === 'virtual'
                  ? this.getId(this.warehouse._embedded.parent)
                  : this.warehouse.id

              query.filter.push({ type: 'eq', field: 'warehouse', value })

              return this.$service.storagePlace.getAll(query)
            },
            onChange: (place) => {
              this.model.place = place
            }
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleClick (type) {
      this.selectedType = type
    },
    loadPath (place) {
      if (!place.path) {
        if (place.type === 'room') {
          return [(place.placeAlias || '')]
        }

        return [place.room, place.row, place.rack, place.shelf].map(x => x || 0)
      }

      return [...place.path, (place.placeAlias || '')]
    },
    handleSelectAll (props) {
      if (this.items.length > 250) {
        this.addErrorNotification('Cannot select more than 250 items')
        return
      }

      props.selected = !props.selected
    },
    handleCheck (item) {
      let hasItem = false

      this.selectedItems = this.selectedItems.filter(x => {
        if (x.id === item.id) {
          hasItem = true
          return false
        }

        return true
      })

      if (this.selectedItems.length > 249) {
        this.addErrorNotification('Cannot select more than 250 items')
        return false
      }

      if (!hasItem) {
        this.selectedItems = [item, ...this.selectedItems]
      }
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    getId (warehouse) {
      if (warehouse.id) {
        return warehouse.id
      }

      return warehouse._links.self.href.split('/').pop()
    },
    show (acceptance, items, warehouse, type) {
      return Promise.resolve(warehouse)
          .then(warehouse => {
            return warehouse.type === 'virtual' && warehouse._embedded
                ? warehouse
                : this.$service.warehouse.get(warehouse.id)
          })
          .then(warehouse => {
            this.acceptance = acceptance
            this.warehouse = warehouse
            this.items = items.filter(i => !i._embedded.place)
            this.type = type

            this.isOpen = true
          })
    },
    hide () {
      this.isOpen = false
    },
    saveItem (item) {
      const query = {
        per_page: item.quantityReal - item.quantityPlace,
        filter: [
          { type: 'eq', field: 'acceptanceItem', value: item.id },
          {
            type: 'orx',
            conditions: [
              { field: 'state', type: 'eq', value: 'new' },
              {
                type: 'andx',
                conditions: [
                  { field: 'state', type: 'eq', value: 'blocked' },
                  { field: 'place', type: 'isNull' },
                  { field: 'reserve', type: 'isNull' }
                ],
                where: 'and'
              },
              {
                type: 'andx',
                conditions: [
                  { field: 'state', type: 'eq', value: 'normal' },
                  { field: 'place', type: 'isNull' }
                ],
                where: 'and'
              }
            ],
            where: 'and'
          },
        ]
      }

      const data = [{ place: this.model.place.id, state: 'normal' }]

      return this.$service.storageItemEntity.patch(data, query, 'application/json+offers', this.model.place.id)
    },
    save () {
      return this.saveItems()
    },
    saveItems (results = []) {
      this.isLoading = true
      return Promise.resolve(this.selectedItems.length > 0)
          .then(hasItems => {
            return hasItems
                ? Promise.resolve(this.selectedItems)
                : Promise.reject(new Error('No items for allocate!'))
          })
          .then(items => {
            this.item = { ...items[0] }
            return Promise.all([this.item, this.saveItem(this.item)])
          })
          .then(([item, result]) => {
            results = [...(results || []), result]

            this.selectedItems = this.selectedItems.slice(1)

            this.items = this.items.filter(({ id }) => id !== item.id)

            if (!this.selectedItems[0]) {
              this.item = null
              this.isLoading = false
              this.hide()
              return results
            }

            return this.save(results)
          })
          .catch(error => {
            console.trace(error)
            this.addErrorNotification(error)
            this.isLoading = false
          })
    }
  }
}
</script>
