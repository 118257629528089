<template>
  <div>
    <q-legend
      class="q-mb-sm"
      :label="$t('Additional Information')"
    />

    <form-builder :schema="schema" />

    <q-dialog v-model="legalEntityModal">
      <q-card
        class="q-pa-sm"
        style="min-width: 40vw;"
      >
        <q-card-section>
          <legal-entity
            @close="handleLegalEntityModal"
            @submit="handleSubmit"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import LegalEntity from '../legal-entities/LegalEntity.vue'

export default {
  name: 'AcceptanceAdditionalProperties',
  components: {
    LegalEntity
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      legalEntityModal: false,
      updateField: ''
    }
  },
  computed: {
    ...mapGetters([
      'acceptance'
    ]),
    schema () {
      return {
        isLoading: !this.acceptance,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Consignor'),
                field: 'consignor',
                value: this.acceptance && this.acceptance?._embedded?.consignor,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.legalEntity.getAll(query)
                },
                onChange: consignor => {
                  this.updateAcceptanceEmbedded({ consignor })
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'add',
                    icon: 'add',
                    color: 'grey-5',
                    size: 'lg',
                    disabled: this.disabled,
                    onClick: () => {
                      this.updateField = 'consignor'
                      this.legalEntityModal = true
                    }
                  }
                ]
              },
              {
                type: 'multiselect',
                label: this.$t('Consignee'),
                field: 'consignee',
                value: this.acceptance && this.acceptance?._embedded?.consignee,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.legalEntity.getAll(query)
                },
                onChange: consignee => {
                  this.updateAcceptanceEmbedded({ consignee })
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'add',
                    icon: 'add',
                    color: 'grey-5',
                    size: 'lg',
                    disabled: this.disabled,
                    onClick: () => {
                      this.updateField = 'consignee'
                      this.legalEntityModal = true
                    }
                  }
                ]
              },
              {
                type: 'multiselect',
                label: this.$t('Payer'),
                field: 'payer',
                value: this.acceptance && this.acceptance?._embedded?.payer,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.legalEntity.getAll(query)
                },
                onChange: payer => {
                  this.updateAcceptanceEmbedded({ payer })
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'add',
                    icon: 'add',
                    color: 'grey-5',
                    size: 'lg',
                    disabled: this.disabled,
                    onClick: () => {
                      this.updateField = 'payer'
                      this.legalEntityModal = true
                    }
                  }
                ]
              },
              {
                type: 'multiselect',
                label: this.$t('Supplier'),
                field: 'supplier',
                value: this.acceptance && this.acceptance?._embedded?.supplier,
                disabled: this.disabled,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.legalEntity.getAll(query)
                },
                onChange: supplier => {
                  this.updateAcceptanceEmbedded({ supplier })
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'add',
                    icon: 'add',
                    color: 'grey-5',
                    size: 'lg',
                    disabled: this.disabled,
                    onClick: () => {
                      this.updateField = 'supplier'
                      this.legalEntityModal = true
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateAcceptance',
      'addErrorNotification',
      'updateAcceptanceEmbedded'
    ]),
    handleSubmit (entity) {
      this.updateAcceptance({
        _embedded: {
          ...this.acceptance._embedded,
          [this.updateField]: entity
        }
      })

      this.legalEntityModal = false
    },
    handleLegalEntityModal () {
      this.legalEntityModal = false
    }
  }
}
</script>
