<template>
  <div>
    <q-legend
        class="q-mb-sm"
        :label="$t('Keywords')"
    />

    <form-builder :schema="schema"/>

  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
export default {
  name: 'AcceptanceKeywords',
  emits: ['change'],
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      country: null,
      updateField: ''
    }
  },
  computed: {
    ...mapGetters([
      'acceptance'
    ]),
    schema () {
      return {
        isLoading: !this.acceptance,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'tag',
                label: this.$t('Keywords'),
                wrapperStyleClasses: 'col-12 col-sm-12 q-pa-xs',
                field: 'docExtended',
                disabled: this.disabled,
                value: this.acceptance?.docExtended,
                onSubmit: (value) => {
                  if (!value) {
                    return
                  }

                  if (this.acceptance && this.acceptance.docExtended) {
                    this.updateAcceptance({ docExtended: [...this.acceptance.docExtended, value] })

                    this.$emit('change', this.acceptance)
                  }
                },
                onRemove: (value) => {
                  this.updateAcceptance({ docExtended: this.acceptance.docExtended.filter(x => x !== value) })
                  this.$emit('change', this.acceptance)
                },
                btnStyleClasses: 'q-pl-sm'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateAcceptance',
      'addErrorNotification',
      'updateAcceptanceEmbedded'
    ]),
  }
}
</script>